<template>
  <!-- js代码错误实时提醒测试 -->
  <div>{{ chuzhixinjiayou.chuzhixinjiayou }}</div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ErrorTest',
  })
</script>
